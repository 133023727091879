// Here you can add other styles
@import "../../node_modules/@coreui/coreui/scss/coreui";

$theme-colors: (
  "primary": #00aaca,
  "danger": #ea4081,
  "warning": #fbf48b,
  "warning-light": #ffed00,
);

.active-row {
  background-color: #f9c0d6 !important;
}

.c-sidebar {
  background: $primary;
  .c-sidebar-nav-item {
    :hover {
      background: $warning-light;
      color: $danger;
    }
    .c-active {
      background-color: $warning;
      color: $danger;
      .c-sidebar-nav-icon {
        color: $danger;
      }
    }
  }
  .c-sidebar-nav-dropdown-items {
    .c-sidebar-nav-item {
      :hover {
        background: $warning-light;
        color: $danger;
      }
      .c-active {
        background-color: $warning;
        color: $danger;
        .c-sidebar-nav-icon {
          color: $danger;
        }
      }
    }
  }
}

.module-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.required {
  color: $danger;
  margin-top: 0.5rem;
  font-size: 12px;
}

.t-checkbox {
  display: flex;
  align-items: center;
  padding: 0 !important;
  input {
    margin-right: 0.5rem;
  }
}

.bg-warning {
  .card-body {
    div {
      color: #000 !important;
    }
    .text-value-lg {
      color: #000 !important;
    }
  }
}
.react-confirm-alert {
  z-index: 10000;
}

.react-confirm-alert-button-group :nth-child(1) {
  background: $danger !important;
}

.react-confirm-alert-button-group :nth-child(2) {
  background: $primary !important;
}

.DateRangePicker {
  display: block;
  width: 100%;

  // div {
  //   width: 100%;
  // }
  .DateRangePickerInput {
    width: 100%;

    .DateInput {
      width: 45%;
    }
    .DateRangePickerInput_arrow {
      width: 10%;
    }
  }
}
